.efyx-logo {
    height: 28px; 
    width: 90px;
  }
  
  @media (min-width: 601px) and (max-width: 960px) {
    .efyx-logo {
      height: 34px;
      width: 100px;
    }
  }
  
  @media (min-width: 961px) {
    .efyx-logo {
      height: 48px;
      width: 120px;
    }
  }
  
  @media (min-width: 961px) {
    .box-view {
      margin-left: 150px;
      margin-right: 150px;
    }
  }