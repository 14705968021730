.details-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 16px; 
}

.details-row {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}

@media (max-width: 767px) {
  .details-container {
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  }
}

.details-row {
  display: flex;
  align-items: center;
  padding-bottom: 10px;
  margin-bottom: 10px;
}

@media (max-width: 600px) {
  .details-row {
    border-bottom: 1px solid #ddd;
  }
  
  .details-row:last-child {
    border-bottom: none; 
  }
  
  .details-row svg {
    margin-bottom: 5px;
    margin-right: 0;
  }
}

.button-root {
  margin-right: 10px;
  text-transform: none;
}

@media (max-width: 600px) {
  .button-root {
    font-size: 14px;
    padding: 8px 16px;
  }
}

@media (min-width: 601px) and (max-width: 1024px) {
  .button-root {
    font-size: 16px;
    padding: 10px 20px;
  }
}